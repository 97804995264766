/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Mobile Dropdown
        /*if (viewportWidth > 1024) {
          $('.mobile-touch').addClass('disabled');
          $('.mobile-touch').hide();
        } else {
          if ($('.mobile-touch').length) {
            $('.mobile-touch').dropdown();
          }
        }*/

        // Offcanvas Menu
        $('.c-offcanvas__toggle').click(function () {
          $(this).toggleClass('active');

          $('.c-offcanvas').toggleClass('active');
        });

        // Remove empty p tags
        $('p:empty').remove();

        // External Links.
        $('a').filter(function () {
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        // Carousel(s)
        if ($('.c-carousel').length) {
          $('.c-carousel').owlCarousel({
            loop: true,
            items: 1,
            autoplay: true,
            dots: true
          });
        }

                // Handle logos carousel
        if ($('.js-carousel-logos').length) {
          $('.js-carousel-logos').slick({
            accessibility: true,
            adaptiveHeight: false,
            autoplay: false,
            autoplaySpeed: 4000,
            arrows: true,
            centerMode: true,
            dots: false,
            fade: false,
            rows: 0,
            pauseOnFocus: false,
            pauseOnHover: true,
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.842 69.374" height="40"><path fill="none" stroke="#ffffff" stroke-width="2" d="M23 .538L1.187 34.687 23 68.836"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.842 69.374" height="40"><path fill="none" stroke="#ffffff" stroke-width="2" d="M1.187.538L23 34.687 1.187 68.836"/></svg></button>',
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
              {
                breakpoint: 1023,
                settings: {
                  arrows: false,
                  dots: false,
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 567,
                settings: {
                  arrows: false,
                  dots: false,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          }).on('setPosition', function (event, slick) {
            slick.$slides.css('height', slick.$slideTrack.height() + 'px')
          });
        }

        // Scroll Top
        $(window).scroll(function () {
          if ($(this).scrollTop() > 600) {
            $('.scrolltop').fadeIn();
          } else {
            $('.scrolltop').fadeOut();
          }
        });

        $('.scrolltop').click(function () {
          $('html, body').animate({ scrollTop: 0 }, 800);
          return false;
        });

        if ($('.c-team-member__link').length) {
          $('.c-team-member__link').fancybox({
            padding: 0,
            nextClick: true
          });
        }

                // Handle number increments
        if ($('.js-increment').length) {
          $('.js-increment').each(function () {



            var numberObject = $(this)
            var numberTop = numberObject.offset().top

            function incrementNumber(object) {
              object.prop('counter', 0).animate({
                counter: object.data('num'),
              }, {
                duration: 2000,
                easing: 'swing',
                step: function (now) {
                  object.children('span').text(Math.ceil(now).toLocaleString())
                },
              })
            }

            $(window).scroll(function () {
              var scrollY = $(this).scrollTop()
              var windowBottom = (scrollY + $(this).innerHeight())

              if (windowBottom >= numberTop) {
                incrementNumber(numberObject)
              }
            })
          })
        }
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {

      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
